import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <Link to="/" className="w-64 mr-3">
          <LogoIcon />
        </Link>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#company">
          会社概要
        </AnchorLink>
        {/* <AnchorLink className="px-4" href="#careers">
          採用
        </AnchorLink>
        */}
        <AnchorLink className="px-4" href="#contact">
          お問い合わせ
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
