import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="container mx-auto py-8 px-3 mt-48 mb-8 text-gray-800">
    <div className="grid grid-cols-1 divide-x divide-gray-400">
      <div className="text-center">
        <Link to="trading">特定商取引法に基づく表記</Link>
      </div>
    </div>

    <p className="text-sm text-gray-600 pt-12 text-center">
      Copyright © 2007 Kubright Inc. All Rights Reserved.
    </p>
  </footer>
);

export default Footer;
