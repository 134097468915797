import React from 'react';

const LogoIcon = () => (
  <svg
    version="1.0"
    id="&#x30EC;&#x30A4;&#x30E4;&#x30FC;_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 432 64"
    enableBackground="new 0 0 432 64"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M26.337,0.168c-2.425,4.812-4.275,9.875-5.525,15.113c3.05,0.687,6.163,1.088,9.312,1.2V-0.27
					C28.85-0.195,27.575-0.045,26.337,0.168z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M43.975,18.955c-3.312,0.737-6.688,1.163-10.1,1.275v9.625h11.237
					C45.013,26.18,44.638,22.543,43.975,18.955z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M37.662,0.168c-1.25-0.225-2.513-0.363-3.787-0.438v16.75c3.15-0.112,6.263-0.513,9.312-1.2
					C41.938,10.055,40.088,4.98,37.662,0.168z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M33.875,46.98v16.75c1.275-0.075,2.537-0.225,3.787-0.438c2.362-4.675,4.25-9.725,5.537-15.112
					C40.15,47.493,37.037,47.093,33.875,46.98z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M48.875,33.605c-0.1,3.987-0.513,7.95-1.25,11.838c3.737,1.112,7.325,2.612,10.763,4.5
					C61.725,45.13,63.663,39.518,64,33.605H48.875L48.875,33.605z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M20.8,48.18c1.212,5.062,3.038,10.138,5.538,15.112c1.25,0.225,2.513,0.362,3.788,0.438V46.98
					C26.962,47.093,23.85,47.493,20.8,48.18z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M20.025,18.955c-0.663,3.587-1.038,7.225-1.138,10.9h11.238v-9.612
					C26.712,20.118,23.325,19.693,20.025,18.955z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M5.613,13.518C2.275,18.318,0.338,23.93,0,29.855h15.125c0.1-4,0.525-7.95,1.25-11.85
					C12.65,16.905,9.038,15.405,5.613,13.518z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M17.163,49.13c-3.125,0.95-6.188,2.2-9.2,3.8c3.637,4.112,8.275,7.287,13.625,9.125
					C19.712,57.905,18.237,53.58,17.163,49.13z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M7.963,10.518c2.937,1.575,6.025,2.837,9.2,3.8c1.075-4.438,2.55-8.763,4.425-12.925
					C16.475,3.143,11.775,6.218,7.963,10.518z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M15.125,33.605H0c0.338,5.912,2.275,11.525,5.613,16.338c3.425-1.888,7.025-3.388,10.762-4.5
					C15.637,41.555,15.225,37.593,15.125,33.605z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M42.4,1.393c1.875,4.162,3.35,8.5,4.425,12.938c3.2-0.962,6.263-2.225,9.212-3.812
					C52.388,6.393,47.75,3.218,42.4,1.393z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M46.838,49.13c-1.075,4.438-2.55,8.763-4.425,12.925c5.35-1.838,9.987-5.013,13.625-9.125
					C53.15,51.405,50.1,50.13,46.838,49.13z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M18.888,33.605c0.1,3.662,0.475,7.312,1.138,10.9c3.312-0.737,6.688-1.162,10.1-1.275v-9.625H18.888z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M58.388,13.518c-3.362,1.85-6.95,3.362-10.763,4.5c0.737,3.888,1.15,7.85,1.25,11.837H64
					C63.662,23.93,61.725,18.33,58.388,13.518z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFEB3B"
              d="M33.875,33.605v9.625c3.412,0.112,6.8,0.537,10.1,1.275c0.662-3.588,1.037-7.225,1.138-10.9H33.875z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          fill="#333333"
          d="M97.443,27.74c0.18-0.84,0.6-1.38,1.32-1.92l23.879-17.579c0.48-0.36,1.14-0.54,1.8-0.54
			c1.2,0,2.52,0.54,3.42,1.68c0.66,0.84,1.02,1.8,1.02,2.76c0,1.02-0.42,1.92-1.08,2.4L107.403,29.66l21.539,19.199
			c0.66,0.6,1.02,1.56,1.02,2.58c0,1.08-0.42,2.34-1.38,3.3c-0.66,0.72-1.68,1.02-2.64,1.02s-1.86-0.24-2.46-0.84L99.423,33.26
			c-0.78-0.72-1.5-1.44-1.92-2.52h-0.36V52.22c0,1.74-1.86,3.479-4.2,3.479s-4.26-1.74-4.26-3.479V11.361
			c0-1.86,1.98-3.66,4.26-3.66s4.2,1.8,4.2,3.66V27.74H97.443z"
        />
        <path
          fill="#333333"
          d="M144.243,35.6c0,8.04,4.5,12.66,12.479,12.66c8.04,0,12.479-4.62,12.479-12.66V11.121
			c0-1.74,1.86-3.42,4.08-3.42c2.22,0,4.08,1.68,4.08,3.42V36.44c0,11.58-8.04,19.259-20.639,19.259s-20.639-7.68-20.639-19.259
			V11.121c0-1.74,1.86-3.42,4.08-3.42c2.22,0,4.08,1.68,4.08,3.42V35.6z"
        />
        <path
          fill="#333333"
          d="M216.781,30.2v0.42l0.84,0.3c5.22,2.34,7.86,7.919,6.78,13.56c-1.26,6.6-6.78,10.439-15.42,10.439h-16.439
			c-5.58,0-7.08-1.2-7.08-7.08V15.561c0-4.86,2.22-7.08,7.08-7.08h18.299c6.779,0,12.3,5.819,12.3,12.179
			c0,3.84-1.86,7.02-5.521,9.36L216.781,30.2z M206.521,27.261c5.4,0,8.46-2.4,8.46-5.94c0-3.3-2.46-5.4-6.84-5.4h-12.06
			c-1.8,0-2.16,0.42-2.16,2.22v9.12H206.521z M193.922,45.32c0,1.8,0.36,2.16,2.16,2.16h12.419c4.86,0,7.74-2.34,7.74-6.06
			c0-4.14-2.76-6.72-7.5-6.72h-14.819V45.32z"
        />
        <path
          fill="#333333"
          d="M261.002,32.36v0.3c3.239,0.54,4.739,2.34,6.239,5.7c3.6,8.22,4.739,13.26,4.739,13.5
			c0,2.1-1.859,3.9-4.319,3.9c-4.26,0-4.38-4.26-7.26-12.299c-1.979-5.64-3.54-7.86-8.159-7.86h-12.78V52.04
			c0,1.8-1.979,3.66-4.14,3.66c-2.1,0-4.02-1.86-4.02-3.66V16.161c0-5.34,2.34-7.68,7.68-7.68h15.72
			c9.899,0,15.119,4.8,15.119,13.199c0,5.04-2.521,8.76-7.2,10.439L261.002,32.36z M251.941,28.161c6.3,0,9.72-2.34,9.72-6.6
			c0-3.6-2.52-5.64-7.199-5.64h-13.14c-1.5,0-1.86,0.42-1.86,1.92v10.32H251.941z"
        />
        <path
          fill="#333333"
          d="M285.902,52.339c0,1.68-1.92,3.36-4.26,3.36s-4.2-1.62-4.2-3.36V11.181c0-1.74,1.92-3.48,4.2-3.48
			c2.34,0,4.26,1.68,4.26,3.48V52.339z"
        />
        <path
          fill="#333333"
          d="M336.301,39.08c0,9.3-8.88,16.619-20.52,16.619c-13.5,0-23.219-10.08-23.219-23.879
			c0-13.979,9.239-24.119,23.698-24.119c10.86,0,19.079,5.88,19.079,10.32c0,2.22-1.56,3.78-3.659,3.78c-1.8,0-3.18-1.08-5.04-2.58
			c-3.359-2.76-6.479-4.08-10.8-4.08c-8.939,0-15.119,6.899-15.119,16.499c0,9.42,6.359,16.62,15.06,16.62
			c7.38,0,12.359-3.78,12.359-8.82v-2.76c0-0.54-0.24-0.84-0.78-0.84h-10.02c-1.62,0-3.239-1.68-3.239-3.66s1.619-3.78,3.239-3.78
			h12.12c3.959,0,6.84,2.82,6.84,6.78V39.08z"
        />
        <path
          fill="#333333"
          d="M376.381,27.561V11.121c0-1.74,1.859-3.42,4.079-3.42s4.08,1.68,4.08,3.42v41.218
			c0,1.68-1.86,3.36-4.08,3.36s-4.079-1.68-4.079-3.36V35h-24.359v17.339c0,1.68-1.86,3.36-4.08,3.36s-4.08-1.68-4.08-3.36V11.121
			c0-1.74,1.86-3.42,4.08-3.42s4.08,1.68,4.08,3.42v16.439H376.381z"
        />
        <path
          fill="#333333"
          d="M415.44,52.279c0,1.74-1.92,3.42-4.26,3.42s-4.2-1.68-4.2-3.42V16.221h-13.14c-1.68,0-3.42-1.8-3.42-3.84
			c0-2.1,1.74-3.959,3.42-3.959h34.739c1.68,0,3.359,1.86,3.359,3.959c0,2.1-1.68,3.84-3.359,3.84h-13.14V52.279z"
        />
      </g>
    </g>
  </svg>
);

export default LogoIcon;
